<template>
  <v-container fluid class="random-article-container pa-4 pa-sm-6 pa-md-8" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8" lg="8" xl="6">
        <v-card v-if="title" class="article-card" elevation="2">
          <v-card-text class="article-header">
            <h1 class="article-title">{{ title }}</h1>
            <div class="article-meta">
              <span class="author">{{ author }}</span>
              <span class="divider">|</span>
              <span class="site">{{ site }}</span>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text class="article-content" v-html="content" @dblclick="like" @touchend="handleTouch"></v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="article-actions">
            <v-btn color="primary" text @click="like" class="action-btn">
              <v-icon left>mdi-thumb-up</v-icon>
              喜欢
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="fetchArticle('https://qidafang.com/api/randomArticle')" class="action-btn">
              <v-icon left>mdi-refresh</v-icon>
              换一篇
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000" top>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">关闭</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: "Read",
  data() {
    return {
      random: false, // 是否是随机模式，true为随机模式，false为顺序模式
      id: '',
      title: '',
      author: '',
      site: '',
      content: '',
      link: '#',
      changing: false,
      snackbar: false,
      snackbarColor: '',
      snackbarText: '',
      touchStartX: 0,
      touchEndX: 0,
      touchTime: 0,
    }
  },
  created() {
    if (this.$route.params && this.$route.params.id) {
      this.random = false
      this.id = this.$route.params.id * 1
      this.fetchArticle('https://qidafang.com/api/readArticle/' + this.id)
    } else {
      this.random = true
      let url = 'https://qidafang.com/api/randomArticle'
      this.fetchArticle(url)
    }
  },
  methods: {
    handleTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
    },
    handleTouchEnd(event) {
      this.touchEndX = event.changedTouches[0].clientX;
      if (Math.abs(this.touchEndX - this.touchStartX) > 200) { // 你可以根据需要调整这个值
        this.fetchArticle('https://qidafang.com/api/randomArticle');
      }
    },
    handleTouch() {
      let now = new Date().getTime();
      let timesince = now - this.touchTime;
      if ((timesince < 600) && (timesince > 0)) {
        // 双击事件
        this.like();
      } else {
        // 单击事件
        // 你可以在这里添加单击事件的处理代码，如果你需要的话
      }
      this.touchTime = new Date().getTime();
    },
    fetchArticle(url) {
      axios.get(url)
        .then(response => {
          let json = response.data;
          this.id = json.id * 1
          this.title = json.title
          this.author = json.author
          this.site = json.site
          this.content = json.text
          this.link = json.link

          this.changing = false
          document.body.scrollTop = document.documentElement.scrollTop = 0
        })
        .catch(error => console.error(error));
    },
    like() {
      axios.post('https://qidafang.com/api/story/friendArticles/love/' + this.id)
        .then(response => {
          let json = response.data;
          if (json.code == 'success') {
            this.snackbarColor = 'success';
            this.snackbarText = json.data;
            this.snackbar = true;
          } else {
            this.snackbarColor = 'error';
            this.snackbarText = json.data;
            this.snackbar = true;
          }
        })
        .catch(error => window.console.error(error));
    }
  }
};
</script>

<style scoped>
.random-article-container {
  background-color: #f8f9fa;
  min-height: calc(100vh - 100px); /* 调整为顶部导航栏和页脚的总高度 */
}

.article-card {
  border-radius: 12px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  background-color: #ffffff;
}

.article-card:hover {
  box-shadow: 0 8px 24px rgba(0,0,0,0.1);
}

.article-header {
  padding: 2rem 2rem 1.5rem;
}

.article-title {
  font-size: 2rem;
  font-weight: 700;
  color: #2c3e50;
  line-height: 1.3;
  margin-bottom: 1rem;
}

.article-meta {
  font-size: 0.95rem;
  color: #7f8c8d;
}

.author, .site {
  font-weight: 500;
}

.divider {
  margin: 0 0.5rem;
}

.article-content {
  padding: 2rem;
  font-size: 1.1rem;
  line-height: 1.8;
  color: #34495e;
}

.article-content ::v-deep p {
  margin-bottom: 1.5rem;
}

.article-content ::v-deep img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 1.5rem 0;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.article-actions {
  padding: 1rem 2rem;
}

.action-btn {
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0.5px;
}

@media (max-width: 600px) {
  .article-header {
    padding: 1.5rem 1.5rem 1rem;
  }

  .article-title {
    font-size: 1.5rem;
  }

  .article-content {
    padding: 1.5rem;
    font-size: 1rem;
  }

  .article-actions {
    padding: 0.75rem 1.5rem;
  }
}
</style>
